:root {
  --background-color: #10051e;
  --container-b-color: #210b3c;
  --elements-b-color: #2a193e;
  --hover-b-color: #3d2657;
  --text-color: white;
  --hover-color: #ff9900;
  --a-color: #b99add;
}
#root {
	display: flex;
	justify-content: center;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	background-color: var(--elements-b-color);
	padding: 2px;
	border-radius: 10px;
}
body{
  background-color: var(--background-color);
  color: var(--text-color);
}
p{
  text-align: center;
  word-break: break-word;
}
a{
  color: var(--text-color);
}
a:hover{
  color: var(--hover-color);
}
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: var(--elements-b-color);
  text-align: center;
}
table td {
	height: 20px;
	max-width: 800px;
}

svg path {
  fill: var(--text-color);
}
svg:hover path {
  fill: var(--hover-color);
}
summary {
	display: block;
}
summary:hover {
	color: var(--hover-color);
  cursor: pointer;
}
.svg-src{
  width: 15px;
  vertical-align: middle;
  padding: 0px 5px;
}
.buttonBox {
	padding: 5px;
	background-color: var(--elements-b-color);
	width: fit-content;
  border-radius: 15px;
  margin: 5px 0px;
}
.appContainer {
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
  margin-bottom: 70px;
}

.appContainer li {
  word-break: break-all;
}

 .eventContainer {
    background-color: var(--container-b-color);
  }
  .eventContainer td {
    padding: 10px;
    word-break: break-all;
  }
  thead {
    text-align: left;
  }
button {
    word-break: break-all;
    margin: 20px;
    background-color: var(--elements-b-color);
    color: var(--text-color);
    padding: 15px 25px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    text-align: center;
  }
  button:hover{
    background-color: var(--hover-b-color);
  }
  .eventCounterContainer {
    display: grid;
    grid-gap: 20px;
    grid-auto-flow: column;
    align-items: start;
  }
  .Logo {
    vertical-align: middle;
    height: 35px;
    width: auto;
    padding: 10px;
}

.Navigation {
  padding: 1rem;
}

.LogoLink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.MenuTitle {
  font-size: 2em;
  font-weight: bold;
} 
#inputRelay {
	padding: 12px;
	border-radius: 10px;
	border: none;
}
.buttonContainer {
	display: flex;
}
.WellKnowRelays {
	text-align: center;
}
.WellKnowRelays button {
	margin: 5px;
}
li button {
	padding: 2px 10px;
	margin: 1px;
}
.loadMoreButton {
	width: 100%;
	margin: 20px 0px;
}
.toggleButton {
	margin: 5px 10px 5px 0px;
	padding: 10px;
}
.disable {
	background: var(--container-b-color);
}
.isImage, .isVideo {
	width: auto;
	max-height: 650px;
	max-width: 500px;
	display: block;
	margin: 15px 0px;
}
.rvalueDescription {
	padding: 15px;
	background: var(--hover-b-color);
	border-radius: 15px;
  text-align: left;
  word-break: break-word;
  width: fit-content;
}
.rvalueDescription > a {
	display: block;
}
@media screen and (max-width: 1250px) {
  .appContainer {
    width: 100%;
    margin: 0px 5px;
  }
  .appContainer {
    margin-bottom: 35px;
  }
  .isImage, .isVideo {
    width: auto;
  }
}
  @media screen and (max-width: 768px) {
    .eventCounterContainer {
      grid-auto-flow: unset;
    }
    .appContainer {
      width: 100%;
      margin: 0px 5px;
    }
    footer {
      height: 65px;
    }
    .inputRelayContainer {
      display: grid;
    }
    .appContainer {
      margin-bottom: 35px;
    }
    .isImage, .isVideo {
      width: auto;
    }

  }
  @media screen and (max-width: 568px) {
    .buttonContainer {
      display: flex;
      flex-direction: column;
    }
    .appContainer {
      margin-bottom: 35px;
    }
    .isImage, .isVideo {
      width: 100%;
    }
}
    
  